import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

import apiClient from "@/plugins/apiClient";
import {
  saveStateToLocalStorage,
  getStateFromLocalStorage,
} from "./storeUtils.js";

export const useOrdersStore = defineStore("order", {
  state: () => ({
    orders: [],
    basket: getStateFromLocalStorage("fairr-basket") || [],
    loading: false,
  }),

  actions: {
    addToBasket(product) {
      this.basket.push({
        ...product,
        quantity: 1,
      });
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    removeFromBasket(productId) {
      this.basket = this.basket.filter(({ id }) => id !== productId);
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    clearBasket(items) {
      this.basket = this.basket.filter(item => !items.some(i => i.id === item.id));
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    updateProductQuantity(productId, quantity) {
      const product = this.basket.find(({ id }) => id === productId);
      if (product) {
        product.quantity = quantity;
        saveStateToLocalStorage("fairr-basket", this.basket);
      }
    },

    async submitOrder({ items, description }) {
      if (this.loading) return;

      try {
        if (items.length === 0) {
          console.error("Failed to submit order: No items in the basket");  
          return;
        }

        this.loading = true;

        const formattedOrderItems = items.map(({ id, quantity }) => ({
          productOfferId: id,
          quantity: quantity + '',
        }));

        const orderId = (await apiClient.post('/orders', {
          description,
          items: formattedOrderItems
        })).data

        this.clearBasket(items);
        return orderId;
      } catch (err) {
        console.error("Failed to submit order", err);
      } finally {
        this.loading = false;
      }
    },

    async fetchOrders() {
      try {
        this.loading = true;
        const response = (await apiClient.get('/orders/for-customer')).data;
        this.orders = mapOrders(response);
        console.log(this.orders);
      } catch (err) {
        console.error("Failed to fetch orders", err);
      } finally {
        this.loading = false;
      }
    }
  },
});

const mapOrders = (orders) => {
  return orders.reduce((acc, order) => {
    const { orderDate, id, storeOrders } = order;
    const formattedOrders = storeOrders.map((storeOrder) => {
      return {
        ...storeOrder,
        orderDate,
        orderId: id,
        uuid: uuidv4(),
      }
    })

    acc.push(...formattedOrders);

    return acc;
  }, [])
}
