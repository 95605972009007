import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development' ? '/' : process.env.VUE_APP_API_HOST + '/'

const apiClient = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const privateRoutes = ['/orders', '/customers', '/auth/refresh-token'];
    const isPrivateRoute = privateRoutes.some((route) => config.url.startsWith(route));
    if (isPrivateRoute) {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authTokenExpires');
      // Redirect to login if unauthorized ?
    }
    return Promise.reject(error);
  }
);

export default apiClient;