import { defineStore } from "pinia";
import apiClient from "@/plugins/apiClient";

import {
  saveStateToLocalStorage,
  getStateFromLocalStorage,
} from "./storeUtils.js";

export const useUserStore = defineStore("user", {
  state: () => ({
    favoriteShops: getStateFromLocalStorage("user-favoriteShops") || [],
    isLoading: false,
    profile: null,
  }),

  getters: {
    isFavoriteShop: (state) => (shopId) => {
      return state.favoriteShops.includes(shopId);
    },
    isUserLoggedIn: (state) => {
      return !!state.profile
    }
  },

  actions: {
    toggleFavoriteShop(shopId) {
      if (this.isFavoriteShop(shopId)) {
        this.favoriteShops = this.favoriteShops.filter(
          (id) => id !== shopId
        );
      } else {
        this.favoriteShops.push(shopId);
      }

      // Save the updated state to localStorage
      saveStateToLocalStorage("user-favoriteShops", this.favoriteShops);
    },

    async login(credentials) {
      const { email, password } = credentials;

      this.isLoading = true;
      try {
        const response = (await apiClient.post('/auth/login', {
          email, password
        })).data

        const { token, expiresAt } = response;
        if (token) {
          localStorage.setItem('authToken', token);
          localStorage.setItem('authTokenExpires', expiresAt);
          this.fetchProfile();
        }
      } catch (error) {
        console.error('Login failed', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async register(payload) {
      this.isLoading = true;
      try {
        const response = await apiClient.post('/auth/signup', payload);
        console.log('Signup successful', response.data);
      } catch (error) {
        console.error('Signup failed', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async refreshToken() {
      try {
        const response = await apiClient.get('/auth/refresh-token');
        const { token, expiresAt } = response.data;
        if (token) {
          localStorage.setItem('authToken', token);
          localStorage.setItem('authTokenExpires', expiresAt);
        }
      } catch (error) {
        console.error('Refresh token failed', error);
        throw error;
      }
    },

    logout() {
      this.profile = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('authTokenExpires');
    },

    async fetchProfile() {
      this.isLoading = true;
      try {
        const res = (await apiClient.get('/customers/for-current-user')).data
        this.profile = prettifyUserData(res);
      } catch (error) {
        console.error('Fetch profile failed', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async updateProfile(data) {
      this.isLoading = true;
      try {
        const response = await apiClient.post('/customers/for-current-user', data);
        console.log('Profile updated', response.data);
      } catch (error) {
        console.error('Fetch profile failed', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async checkUserToken() {
      const tokenDetails = getTokenDetails();

      if (!tokenDetails) {
        return;
      }

      if (isTokenExpired(tokenDetails)) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenExpires');
        return;
      }

      this.setTokenRefreshTimeout(tokenDetails);

      await this.fetchProfile();
    },

    setTokenRefreshTimeout(tokenDetails) {
      const { timeUntilRefresh } = tokenDetails;

      if (timeUntilRefresh <= 0) {
        this.refreshToken();
      } else {
        setTimeout(() => {
          this.refreshToken();
        }, timeUntilRefresh);
      }
    }
  },
});

const getTokenDetails = () => {
  const ONE_HOUR_IN_MS = 60 * 60 * 1000;
  const token = localStorage.getItem('authToken');
  const tokenExpires = localStorage.getItem('authTokenExpires');

  if (!token || !tokenExpires) return null;

  const expirationTime = new Date(+tokenExpires).getTime();
  const currentTime = Date.now();
  const timeUntilExpiration = expirationTime - currentTime;
  const timeUntilRefresh = timeUntilExpiration - ONE_HOUR_IN_MS;

  return { token, expirationTime, timeUntilRefresh, timeUntilExpiration };
}

const isTokenExpired = ({ timeUntilExpiration }) => {
  return timeUntilExpiration <= 0;
}


const prettifyUserData = (data) => {
  const profile = {
    email: data.userEmail,
    name: data.userFullName,
  }

  if (data.customer) {
    profile.details = {
      ...data.customer,
      address: data.customer?.address || data.customer?.customerAddress,
    }
  }

  return profile;
}
