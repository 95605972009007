<template>
  <v-dialog v-model="isDialogOpen" max-width="420">
    <component
      :is="activeFormComponent"
      :context="context"
      @close="closeDialog"
      @authCompleted="authCompleted"
      @switchForm="switchForm"
      class="auth-form"
    />
  </v-dialog>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    modelValue: Boolean,
    context: String,
  },
  data() {
    return {
      activeForm: 'login',
    };
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    activeFormComponent() {
      if (this.isLoginActive) {
        return defineAsyncComponent(() => import('./LoginForm.vue'));
      } else {
        return defineAsyncComponent(() => import('./RegisterForm.vue'));
      }
    },
    isLoginActive() {
      return this.activeForm === 'login';
    },
  },
  methods: {
    closeDialog() {
      this.isDialogOpen = false;
    },
    switchForm() {
      this.activeForm = this.isLoginActive ? 'register' : 'login';
    },
    authCompleted() {
      this.isDialogOpen = false;
      this.$emit('authCompleted');
    },
  },
};
</script>

<style lang="scss">
.auth-form__help {
  text-align: center;
  font-size: 14px;
  opacity: 0.8;
  margin: 24px 0 12px;
}

.form-overlay {
  text-align: center;
  display: flex;
  padding: 24px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form-overlay__content {
  margin: auto;

  .v-icon {
    font-size: 46px;
  }
}
</style>
